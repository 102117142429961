export const ratebeerBestUrl = () => '/ratebeerbest'

type Page2019 =
  | 'new-brewers'
  | 'best-beers-in-the-world'
  | 'best-by-country'
  | 'best-by-style'
  | 'best-reviewers'
  | 'brewers-by-subregion'
  | 'places-by-country'
  | 'places-by-subregion'
  | 'best-brewers-top-100'
  | 'media-assets'

type Page2020 =
  | 'new-brewers'
  | 'best-beers-in-the-world'
  | 'best-by-country'
  | 'best-by-style'
  | 'best-reviewers'
  | 'brewers-by-subregion'
  | 'places-by-country'
  | 'places-by-subregion'
  | 'best-brewers-top-100'
  | 'media-assets'

export const ratebeerBest2019Url = (page?: Page2019) =>
  page ? `/ratebeerbest/2019/${page}` : '/ratebeerbest/2019'

export const ratebeerBest2020Url = (page?: Page2020) =>
  page ? `/ratebeerbest/2020/${page}` : '/ratebeerbest/2020'
