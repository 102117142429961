import { iconFromFontAwesome } from 'lib/icons/tools'

import { faApple } from '@fortawesome/free-brands-svg-icons'
export const AppleIcon = iconFromFontAwesome({
  icon: faApple,
})

import { faGooglePlay } from '@fortawesome/free-brands-svg-icons'
export const GooglePlayIcon = iconFromFontAwesome({
  icon: faGooglePlay,
})

import { faInstagram } from '@fortawesome/free-brands-svg-icons'
export const InstagramIcon = iconFromFontAwesome({
  icon: faInstagram,
})

import { faTwitter } from '@fortawesome/free-brands-svg-icons'
export const TwitterIcon = iconFromFontAwesome({
  defaultColor: '#1aa1f1',
  icon: faTwitter,
})

import { faFacebook } from '@fortawesome/free-brands-svg-icons'
export const FacebookIcon = iconFromFontAwesome({
  defaultColor: '#4267b2',
  icon: faFacebook,
})

import { faClipboard } from '@fortawesome/free-solid-svg-icons'
export const ClipboardIcon = iconFromFontAwesome({
  icon: faClipboard,
})

import { faImage } from '@fortawesome/free-solid-svg-icons'
export const EditImageIcon = iconFromFontAwesome({
  icon: faImage,
})

import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
export const StarHalfAltIcon = iconFromFontAwesome({
  icon: faStarHalfAlt,
})

import { faBeer } from '@fortawesome/free-solid-svg-icons'
export const BeerIcon = iconFromFontAwesome({
  icon: faBeer,
})

import { faBarcode } from '@fortawesome/free-solid-svg-icons'
export const BarcodeIcon = iconFromFontAwesome({
  icon: faBarcode,
})

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
export const CalendarAltIcon = iconFromFontAwesome({
  icon: faCalendarAlt,
})

import { faCertificate } from '@fortawesome/free-solid-svg-icons'
export const CertificateIcon = iconFromFontAwesome({
  icon: faCertificate,
})

import { faSave } from '@fortawesome/free-solid-svg-icons'
export const SaveIcon = iconFromFontAwesome({
  icon: faSave,
})

import { faTruck } from '@fortawesome/free-solid-svg-icons'
export const TruckIcon = iconFromFontAwesome({
  icon: faTruck,
})

import { faChartBar } from '@fortawesome/free-solid-svg-icons'
export const BarChartIcon = iconFromFontAwesome({
  icon: faChartBar,
})

import { faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons'
export const LongArrowAltUpIcon = iconFromFontAwesome({
  icon: faLongArrowAltUp,
})

import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'
export const LongArrowAltDownIcon = iconFromFontAwesome({
  icon: faLongArrowAltDown,
})

import { faTags } from '@fortawesome/free-solid-svg-icons'
export const TagsIcon = iconFromFontAwesome({
  icon: faTags,
})

import { faFile } from '@fortawesome/free-solid-svg-icons'
export const FileIcon = iconFromFontAwesome({
  icon: faFile,
})

import { faArchive } from '@fortawesome/free-solid-svg-icons'
export const ArchiveIcon = iconFromFontAwesome({
  icon: faArchive,
})

import { faSlidersH } from '@fortawesome/free-solid-svg-icons'
export const SlidersIcon = iconFromFontAwesome({
  icon: faSlidersH,
})

import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
export const EnvelopeIcon = iconFromFontAwesome({
  icon: faEnvelope,
})

import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
export const GlobeIcon = iconFromFontAwesome({
  icon: faGlobeAmericas,
})
